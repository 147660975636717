<template>
  <div class="base">
    <div id="three"></div>
    <div class="main">
      <input type="email" v-model="mailad" placeholder="メールアドレスを入力してください" />
      <p class="little">MAILADDRESS</p>
      <input type="password" v-model="pass" placeholder="パスワードを入力してください" />
      <p class="little">PASSWORD</p>
      <p class="error">{{ message }}</p>
      <div class="button">
        <div>
          <router-link to="/subscribe" class="subscribe">新規登録はこちら</router-link>
        </div>
        <div @click="login()" class="login">
          <p>ログイン</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from '@/firebase/firebase'
import * as THREE from 'three'
import testfont from 'three/examples/fonts/gentilis_bold.typeface.json'

export default {
  name: 'Login',
  data () {
    return {
      mailad: '',
      pass: '',
      width: window.innerWidth,
      height: window.innerHeight,
      message: ''
    }
  },
  methods: {
    login () {
      firebase.auth().signInWithEmailAndPassword(this.mailad, this.pass)
        .then(
          this.mailad = '',
          this.pass = ''
        )
        .catch((error) => {
          console.log(error.message)
          if (error.message === 'The password is invalid or the user does not have a password.') {
            this.message = '※パスワードが正しくありません'
          }
          if (error.message === 'There is no user record corresponding to this identifier. The user may have been deleted.') {
            this.message = '※ユーザーが存在しません'
          }
        })
    },
    init () {
      this.scene = new THREE.Scene()
      const font = new THREE.FontLoader().parse(testfont)
      this.scene.background = new THREE.Color(0xf5f5f5)
      this.renderer = new THREE.WebGLRenderer()
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.width, this.height)
      this.camera = new THREE.PerspectiveCamera(
        45,
        this.width / this.height,
        1,
        10000
      )
      // 図形を作成
      this.textGeometry = new THREE.TextGeometry('LOGIN', {
        font: font,
        size: 100,
        height: 50,
        curveSegments: 10,
        bevelThickness: 3,
        bevelSize: 1.0,
        bevelEnabled: true
      })
      this.textGeometry.center()
      this.materialThree = new THREE.MeshPhongMaterial({ color: 0x00ff00 })
      this.text = new THREE.Mesh(this.textGeometry, this.materialThree)
      this.scene.add(this.text)
      // ライト
      const directionalLight = new THREE.DirectionalLight(0xffffff, 2)
      directionalLight.position.set(1, 1, 1)
      this.scene.add(directionalLight)
      this.camera.position.set(0, 0, +1000)
      var canvasWrap = document.getElementById('three')
      this.renderer.setSize(this.width, this.height)
      canvasWrap.appendChild(this.renderer.domElement)
      this.renderer.render(this.scene, this.camera)
      this.tick()
    },
    tick () {
      requestAnimationFrame(this.tick)
      this.text.rotation.x += 0.01
      this.text.rotation.y += 0.01
      this.renderer.render(this.scene, this.camera)
    }
  },
  mounted () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$router.push('/')
      }
    })
    this.init()
  }
}
</script>

<style scoped>
.main {
  background-color: rgba(170, 170, 170, 0.397);
  padding: 10px;
}

.error {
  color: red;
  font-size: 14px;
}

#three {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.main input {
  display: block;
  height: 25px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  background-color: rgba(0, 0, 0, 0);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid black;
}

.main input::placeholder {
  color: white;
}

.button {
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  align-items: center;
  margin-top: 20px;
}

.login p{
  margin: 0;
}

.subscribe {
  color: black;
  text-decoration: none;
  font-size: 14px;
  border-bottom: 1px solid black;
}

.login {
  border: 1px solid black;
  color: rgb(40, 40, 40);
  cursor: pointer;
  padding: 5px 20px;
  letter-spacing: 3px;
}

.little {
  font-size: 12px;
  margin: 0;
  letter-spacing: 3px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 950px) {
  .login {
    padding: 2px 10px;
    width: 130px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .main {
    width: 300px;
    margin: 250px auto;
  }

  .main input {
    width: 250px;
  }

  .little {
    width: 250px;
  }

  .button {
    width: 300px;
  }
}

@media screen and (min-width: 751px) {
  .main {
    width: 500px;
    margin: 200px auto;
  }

  .main input {
    width: 350px;
  }

  .little {
    width: 350px;
  }

  .button {
    width: 350px;
    display: flex;
  }
}
</style>
